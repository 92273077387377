import { createReducer } from '@reduxjs/toolkit';
import { setMenuActive } from './actions';

const initialState = {
  menuActive: false,
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMenuActive, (state, action) => {
    state.menuActive = action.payload;
  });
});
