import { Divider, SocialMediaButton } from '@/lib';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { Button } from '@/lib/Button';
import { SwapModal } from '../SwapModal';
import { ContactUsModal } from '../ContactUsModal';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import {
  MEDIUM_URL,
  TABLET_RESOLUTION,
  TELEGRAM_URL,
  XCOM_URL,
} from '@/constants';
import { useConnectBahamut } from '@/hooks/useConnectBahamut';

export const Footer = () => {
  const { address, chainId } = useWeb3ModalAccount();
  const [isAppChainConnected, setIsAppChainConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [swapModalOpen, setSwapModalOpen] = useState(false);
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const width = useWindowWidth();
  const isTablet = width && width < TABLET_RESOLUTION;
  const { connect } = useConnectBahamut(chainId);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      setIsAppChainConnected(chainId === Number(process.env.CHAIN_ID));
    }
  }, [chainId, loading]);

  const handleAddBahamut = () => {
    if (!address) {
      connect();
    }
  };

  if (loading) return null;

  return (
    <>
      {contactUsModalOpen && (
        <ContactUsModal onCloseModal={() => setContactUsModalOpen(false)} />
      )}
      {swapModalOpen && <SwapModal onClose={() => setSwapModalOpen(false)} />}
      <footer className={styles.footer}>
        <div className={styles.mainContainer}>
          <div className={styles.topSection}>
            <img src="/png/logo.png" className={styles.logo} alt="logo" />
            <div className={styles.linkColumns}>
              <div className={styles.linkColumn}>
                <FooterTitle>Marketplace</FooterTitle>
                <FooterLink href="/">Home</FooterLink>
                <FooterLink href="/explore">Explore</FooterLink>
                <div
                  className={styles.footerLink}
                  onClick={() => setSwapModalOpen(true)}
                >
                  Swap
                </div>
                <FooterLink href="/blog">Blog</FooterLink>
              </div>
              <div className={styles.linkColumn}>
                <FooterTitle>Company</FooterTitle>
                <FooterLink href="/about">About</FooterLink>
                <FooterLink href="/careers">Careers</FooterLink>
                <FooterLink href="/team">Team</FooterLink>
              </div>
              <div className={styles.linkColumn}>
                <FooterTitle>My Account</FooterTitle>
                <FooterLink href="/profile">Profile</FooterLink>
              </div>
            </div>
            {isTablet && <Divider className={styles.divider} noMargin />}
            <div className={styles.actions}>
              <div className={styles.socialMediaSection}>
                <FooterTitle>Join the community</FooterTitle>
                <div className={styles.socialMediaButtons}>
                  <SocialMediaButton type="telegram" url={TELEGRAM_URL} />
                  <SocialMediaButton type="xcom" url={XCOM_URL} />
                  <SocialMediaButton type="medium" url={MEDIUM_URL} />
                </div>
                <div className={styles.contactInfo}>
                  <div className={styles.contactDetails}>
                    <FooterContactInfo>+62 819 494 80 500</FooterContactInfo>
                    <FooterContactInfo>|</FooterContactInfo>
                    <FooterContactInfo>help@8legends.ai</FooterContactInfo>
                  </div>
                  <FooterContactInfo>
                    Graha Merdeka Renon Unit 15,
                    <br />
                    Jl. Merdeka - Kota Denpasar, Bali, Indonesia
                  </FooterContactInfo>
                </div>
              </div>

              <div className={styles.helpSection}>
                <FooterTitle>Need Help?</FooterTitle>
                <Button
                  onClick={() => setContactUsModalOpen(true)}
                  variant="filled"
                  color="gray"
                  size="small"
                >
                  Contact Support
                </Button>
              </div>
            </div>
          </div>
          <Divider className={styles.divider} />
          <div className={styles.bottomSection}>
            <p className={styles.copyright}>© 2024 8 Legends Marketplace</p>
            <div className={styles.bottomLinks}>
              <Link className={styles.bottomLink} href="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className={styles.bottomLink} href="/terms-of-service">
                Terms of Service
              </Link>
              {!isAppChainConnected && (
                <Button
                  onClick={handleAddBahamut}
                  variant="filled"
                  color="gray"
                >
                  {address ? 'Switch to' : 'Add'} Bahamut
                </Button>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const FooterLink = ({ href, children }) => {
  return (
    <Link className={styles.footerLink} href={href}>
      {children}
    </Link>
  );
};

const FooterTitle = ({ children }) => {
  return <span className={styles.footerTitle}>{children}</span>;
};

const FooterContactInfo = ({ children }) => {
  return <span className={styles.footerContactInfo}>{children}</span>;
};
