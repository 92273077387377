import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Popover.module.scss';
import { Flex } from '../Flex';
import { copyToClipboard } from '@/services/copy-to-clipboard';
import { Button, Divider } from '..';
import { Icon } from '../Icons';

export const Popover = ({
  content,
  linkToCopy,
  className = '',
  buttonClassName = '',
  buttonIconType,
  buttonContent,
  iconClassName = '',
  triggerClassName = '',
  onStateChange,
  position,
  style,
}) => {
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const hideTimeoutRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [isHoveringPopup, setIsHoveringPopup] = useState(false);

  useLayoutEffect(() => {
    if (open && popupRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const popupHeight = popupRef.current.offsetHeight;
      const popupWidth = popupRef.current.offsetWidth;
      const coordinates = {
        top: rect.top - popupHeight - 8 + 'px',
      };

      switch (position) {
        case 'center':
          coordinates.left = rect.left + rect.width / 2 - popupWidth / 2 + 'px';
          break;
        case 'right':
          coordinates.left = rect.right - popupWidth + 'px';
          break;
        case 'left':
        default:
          coordinates.left = rect.left + 'px';
      }

      setAnchorPosition(coordinates);
    }
  }, [open, position]);

  const handleMouseEnterIcon = () => {
    clearTimeout(hideTimeoutRef.current);
    setOpen(true);
    if (onStateChange) onStateChange(true);
  };

  const handleMouseLeaveIcon = () => {
    hideTimeoutRef.current = setTimeout(() => {
      if (!isHoveringPopup) {
        setOpen(false);
        if (onStateChange) onStateChange(false);
      }
    }, 200);
  };

  const handleMouseEnterPopup = () => {
    clearTimeout(hideTimeoutRef.current);
    setIsHoveringPopup(true);
  };

  const handleMouseLeavePopup = useCallback(() => {
    setIsHoveringPopup(false);
    hideTimeoutRef.current = setTimeout(() => {
      setOpen(false);
      if (onStateChange) onStateChange(false);
    }, 0);
  }, [onStateChange]);

  const handleCopyClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(linkToCopy);
    setOpen(false);
    setIsHoveringPopup(false);
    if (onStateChange) onStateChange(false);
  };

  const handlePopupClick = () => {
    setOpen(false);
    setIsHoveringPopup(false);
    if (onStateChange) onStateChange(false);
  };

  useEffect(() => {
    if (open) {
      const handleOutsideClick = (e) => {
        if (!e.target.closest(`.${styles.popoverContainer}`)) {
          handleMouseLeavePopup();
        }
      };

      const handleScroll = () => {
        handleMouseLeavePopup();
      };

      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('scroll', handleScroll);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [open, handleMouseLeavePopup]);

  useEffect(() => {
    return () => {
      clearTimeout(hideTimeoutRef.current);
    };
  }, []);

  return (
    <>
      <div
        className={triggerClassName}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleMouseEnterIcon();
        }}
        onMouseEnter={handleMouseEnterIcon}
        onMouseLeave={handleMouseLeaveIcon}
      >
        <Button ref={buttonRef} variant="ghost" className={buttonClassName}>
          {buttonIconType && (
            <Icon type={buttonIconType} className={iconClassName} />
          )}
          {buttonContent}
        </Button>
      </div>
      {open && (
        <div
          ref={popupRef}
          className={cn(styles.popoverContainer, className)}
          onClick={handlePopupClick}
          style={{
            position: 'fixed',
            ...anchorPosition,
            ...style,
          }}
          onMouseEnter={handleMouseEnterPopup}
          onMouseLeave={handleMouseLeavePopup}
        >
          {linkToCopy && (
            <>
              <Flex
                align="center"
                gap={6}
                className={styles.shareItem}
                onClick={handleCopyClick}
              >
                <img src="/svg/copy.svg" alt="Copy link icon" /> Copy link
              </Flex>

              <Divider noMargin />
            </>
          )}

          {content}
        </div>
      )}
    </>
  );
};

Popover.propTypes = {
  content: PropTypes.node.isRequired,
  linkToCopy: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonIconType: PropTypes.string,
  buttonContent: PropTypes.node,
  iconClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  onStateChange: PropTypes.func,
  position: PropTypes.string,
};
