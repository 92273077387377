/* eslint-disable no-console */
import axios, { AxiosError } from 'axios';

export async function fetchData({ url, method = 'GET', body }) {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options.data = body;
    }

    const response = await axios(url, options);

    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching data');
    if (error instanceof AxiosError) {
      return error.response;
    }
  }
}
