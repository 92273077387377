/* eslint-disable no-console */
import { getGlobalState, setGlobalState } from '@/store';
import abi from '../abis/src/contracts/Auction.sol/Auction.json';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';

const ContractAddress = process.env.CONTRACT_ADDRESS;
const ContractAbi = abi.abi;
let tx;

export const toWei = (num) => ethers.utils.parseEther(num?.toString());
export const fromWei = (num) =>
  num ? ethers.utils.formatEther(BigInt(num || 0)?.toString()) : num;
export const numberToDigits = (n, symbols) =>
  n ? n.toLocaleString('en', { maximumFractionDigits: symbols }) : String(n);
// TODO: remove if not needed
// const connectedAccount = getGlobalState('connectedAccount');
// const contract = getGlobalState('contract');

const showErrorToast = (identifier, error) => {
  toast.error(`Error in ${identifier}: ${error.message || error}`);
};

const getEthereumContract = async () => {
  try {
    const { ethereum } = window;
    const provider = await new ethers.providers.Web3Provider(ethereum);
    const signer = await provider.getSigner();
    const contract = await new ethers.Contract(
      ContractAddress,
      ContractAbi,
      signer,
    );

    await setGlobalState('contract', contract);
    return contract;
  } catch (e) {
    console.error(e);
  }
};

const redirectToMetamask = async (platform) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        if (platform == 'ios') {
          window.location.href = 'https://apps.apple.com/app/id1438144202';
        } else if (platform == 'android') {
          window.location.href =
            'https://play.google.com/store/apps/details?id=io.metamask';
        } else {
          window.open(
            'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
            '_blank',
          );
        }
      }, 2000);
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

const disconnect = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_revokePermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  } catch (e) {
    console.error(e);
  }

  // await web3.currentProvider.disconnect();
};

const isWallectConnected = async () => {
  try {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (!accounts?.length) {
      return false;
    }

    const balanceHash = await ethereum.request({
      method: 'eth_getBalance',
      params: [accounts[0], 'latest'],
    });
    const balance = parseInt(balanceHash, 16) / Math.pow(10, 18);
    await setGlobalState('balance', balance);
    if (typeof window !== 'undefined') {
      window.ethereum.on('accountsChanged', async () => {
        await isWallectConnected();
      });
    }

    if (accounts?.length) {
      // setGlobalState("connectedAccount", account);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

let isConnecting = false;

const connectWallet = async () => {
  if (await isConnecting) {
    return;
  }

  try {
    const { ethereum } = window;
    if (!ethereum) throw new Error('Ethereum объект не найден.');

    isConnecting = true;
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    if (!account) {
      throw new Error('Account not found');
    }

    const balanceHash = await ethereum.request({
      method: 'eth_getBalance',
      params: [accounts[0], 'latest'],
    });
    const balance = parseInt(balanceHash, 16) / Math.pow(10, 18);
    await setGlobalState('balance', balance);
    await loadAuctions();
    return true;
  } catch (error) {
    return false;
  } finally {
    isConnecting = false;
  }
};

const createNFTItem = async (
  { name, description, image, video, metadataURI, price },
  connectedAccount,
) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      return false;
    }
    if (!connectedAccount) {
      toast.warn('Connect to account');
      return false;
    }
    const file = image || video;

    const contract = await getEthereumContract();
    tx = await contract.createAuction(
      name,
      description,
      file,
      metadataURI,
      toWei(price),
      {
        from: connectedAccount,
        value: toWei(0.02),
      },
    );

    await tx.wait();
    await loadAuctions();
    await loadCollections(connectedAccount);
    return true;
  } catch (error) {
    return false;
  }
};

const updatePrice = async ({ tokenId, price }) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      if (typeof window !== 'undefined') {
        setTimeout(() => {
          window.open(
            'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
            '_blank',
          );
        }, 2000);
      }
      return;
    }

    const connectedAccount = getGlobalState('connectedAccount');
    const contract = await getEthereumContract();
    tx = await contract.changePrice(
      tokenId,
      //price,
      toWei(price),
      {
        from: connectedAccount,
      },
    );
    await tx.wait();
    await loadAuctions();
    await loadCollections(connectedAccount);
  } catch (error) {
    showErrorToast('updatePrice', error);
  }
};

const offerItemOnMarket = async ({
  tokenId,
  biddable,
  sec,
  min,
  hour,
  day,
  price,
}) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }

    const connectedAccount = getGlobalState('connectedAccount');
    const contract = await getEthereumContract();
    tx = await contract.offerAuction(
      tokenId,
      toWei(price),
      biddable,
      sec,
      min,
      hour,
      day,
      {
        from: connectedAccount,
      },
    );
    toast.loading(`Processing...`);
    await tx.wait();
    await loadAuctions();
    // eslint-disable-next-line no-undef
    await getMyAuctions();
    toast.dismiss();
    return true;
  } catch (error) {
    toast.error(`offerItemOnMarket REJECTED`);
    return false;
  }
};

const buyNFTItem = async ({ tokenId, price }) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }

    const connectedAccount = getGlobalState('connectedAccount');
    const contract = await getEthereumContract();
    tx = await contract.buyAuctionedItem(tokenId, {
      from: connectedAccount,
      //value: price,
      value: toWei(price),
    });
    await tx.wait();
    await loadAuctions();
    await loadAuction(tokenId);
  } catch (error) {
    showErrorToast('buyNFTItem', error);
  }
};

const bidOnNFT = async ({ tokenId, price }) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }

    const connectedAccount = getGlobalState('connectedAccount');
    const contract = await getEthereumContract();
    tx = await contract.placeBid(tokenId, {
      from: connectedAccount,
      //value: price,
      value: toWei(price),
    });

    await tx.wait();
    await getBidders(tokenId);
    await loadAuction(tokenId);
  } catch (error) {
    showErrorToast('bidOnNFT', error);
  }
};

const claimPrize = async ({ tokenId, id }) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }
    const connectedAccount = getGlobalState('connectedAccount');
    const contract = await getEthereumContract();
    tx = await contract.claimPrize(tokenId, id, {
      from: connectedAccount,
    });
    await tx.wait();
    await getBidders(tokenId);
  } catch (error) {
    showErrorToast('claimPrize', error);
  }
};

const loadAuctions = async () => {
  try {
    const contract = await getEthereumContract();
    const auctions = await contract?.getLiveAuctions();
    if (auctions) setGlobalState('auctions', structuredAuctions(auctions));
  } catch (error) {
    showErrorToast('loadAuctions', error);
    return null;
  }
};

const loadAuction = async (id) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }
    const contract = await getEthereumContract();
    const auction = await contract.getAuction(id);
    setGlobalState('auction', structuredAuctions([auction])[0]);
  } catch (error) {
    showErrorToast('loadAuction', error);
  }
};

const getBidders = async (id) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }
    const contract = await getEthereumContract();
    const bidders = await contract.getBidders(id);
    setGlobalState('bidders', structuredBidders(bidders));
  } catch (error) {
    showErrorToast('getBidders', error);
  }
};

const fetchPinataData = async (tokenURI) => {
  try {
    const response = await fetch(tokenURI);
    if (!response.ok) {
      throw new Error(`Error fetching metadata from ${tokenURI}`);
    }
    const metadata = await response.json();
    return metadata;
  } catch (error) {
    showErrorToast('fetchPinataData', error);
    return {};
  }
};

const loadCollections = async () => {
  try {
    const connectedAccount = getGlobalState('connectedAccount');
    if (!connectedAccount) {
      console.error('Connected account is null');
    }
    //await redirectToMetamask();
    setGlobalState('collections', 'pending');
    const contract = await getEthereumContract();
    if (!contract) {
      console.error('Contract object is null');
      return;
    }
    if (!connectedAccount) {
      console.error('Connected account is null');
      return;
    }
    const collections = await contract.getMyAuctions({
      from: connectedAccount,
    });

    const promises = collections?.map(async (auction) => {
      const tokenURI = await contract.tokenURI(auction.tokenId);
      if (!tokenURI) {
        console.error('Invalid tokenURI:', tokenURI);
        return null;
      }
      const pinataData = await fetchPinataData(tokenURI);
      return {
        ...pinataData,
        ...auction,
        tokenURI,
      };
    });

    const updatedCollections = await Promise.all(promises);
    const filteredCollections = updatedCollections?.filter(
      (auction) => auction !== null,
    );
    setGlobalState('collections', structuredAuctions(filteredCollections));
  } catch (error) {
    setGlobalState('collections', []);
    showErrorToast('loadCollections', error);
  }
};

const structuredAuctions = (auctions) =>
  auctions
    ?.map((auction) => ({
      tokenId: auction.tokenId.toNumber(),
      owner: auction.owner.toLowerCase(),
      seller: auction.seller.toLowerCase(),
      winner: auction.winner?.toLowerCase(),
      name: auction.name,
      description: auction.description,
      duration: Number(auction.duration + '000'),
      image: auction.image,
      video: auction.video,
      price: fromWei(auction.price),
      bids: auction.bids,
      //price: BigNumber.from(auction.price?._hex)?.toString(),
      //price: auction.price,
      biddable: auction.biddable,
      sold: auction.sold,
      live: auction.live,
    }))
    .reverse();

const structuredBidders = (bidders) =>
  bidders
    ?.map((bidder) => ({
      timestamp: Number(bidder.timestamp + '000'),
      bidder: bidder.bidder.toLowerCase(),
      //price: BigNumber.from(bidder.price?._hex)?.toString(),
      price: fromWei(bidder.price),
      refunded: bidder.refunded,
      won: bidder.won,
    }))
    .sort((a, b) => b.price - a.price);

export {
  isWallectConnected,
  connectWallet,
  createNFTItem,
  loadAuctions,
  loadAuction,
  loadCollections,
  offerItemOnMarket,
  buyNFTItem,
  bidOnNFT,
  getBidders,
  claimPrize,
  updatePrice,
  redirectToMetamask,
  disconnect,
};
