import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import styles from './TextArea.module.scss';
import { Button, Alert, Flex } from '..';

export function TextArea({
  label,
  defaultValue,
  placeholder,
  required,
  button,
  name,
  maxLength = 1000,
  minLength = 0,
  pattern,
  className,
  disabled,
  content,
  readonly,
  description,
  height,
  alert,
}) {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    rules: {
      required: { value: required, message: 'required' },
      maxLength: { value: maxLength, message: 'maxLength' },
      minLength: { value: minLength, message: 'minLength' },
      pattern: { value: pattern, message: 'pattern' },
    },
    defaultValue,
    control,
  });
  const error = fieldState.error ? fieldState.error.message : '';
  const [charCount, setCharCount] = useState(0);
  const wrapperClassName = cn(styles.wrapper, className);
  const inputClassName = cn(styles.input, {
    [styles.error]: error,
    [styles.disabled]: disabled,
    [styles.readonly]: readonly,
  });
  const contentClassName = cn(styles.insideContent, {
    [styles.disabled]: disabled,
  });

  const handleChange = (e) => {
    let inputValue = e.target.value;

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
      return;
    }

    field.onChange(inputValue);
    setCharCount(inputValue.length);
  };

  const handleError = () => {
    switch (error) {
      case 'required':
        return 'This field is required';
      case 'pattern':
        return 'Invalid field format';
      default:
        return 'Error in filling out the field';
    }
  };

  return (
    <div className={wrapperClassName}>
      <div className={styles.header}>
        {label && (
          <Flex gap={4}>
            <p>{label}</p>
            {required && <span className={styles.required}>*</span>}
          </Flex>
        )}
        {button && (
          <Button
            text={button.text}
            onClick={button.onClick}
            variant="ghost"
            className={styles.button}
            tabIndex={-1}
            type="button"
          />
        )}
      </div>
      <textarea
        {...field}
        onBlur={field.onBlur}
        placeholder={placeholder}
        onChange={handleChange}
        className={inputClassName}
        style={{ height }}
      />
      <span className={styles.charCount}>
        {charCount} / {maxLength}
      </span>
      <div className={contentClassName}>{content && content}</div>
      {error ? (
        <p className={styles.errorTag}>{handleError()}</p>
      ) : (
        <>
          {description && (
            <p className={styles.description}>
              <span>{description}</span>
            </p>
          )}
          {alert && <Alert text={alert} />}
        </>
      )}
    </div>
  );
}
