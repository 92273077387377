import React from 'react';
import styles from './HeaderInput.module.scss';

export const HeaderInput = () => {
  return (
    <div className={styles.headerInputContainer}>
      <div className={styles.inputWrapper}>
        <img src="/svg/loopIcon.svg" alt="search icon" />
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Search adventure"
        />
      </div>
      <div className={styles.selectWrapper}>
        <span className={styles.selectText}>All Items</span>
        <img src="/svg/selectArrow.svg" alt="select arrow" />
      </div>
    </div>
  );
};
