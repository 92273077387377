import { FormProvider, useForm } from 'react-hook-form';
import { BaseModal, Select, Input, TextArea, Flex } from '@/lib';

import { useState } from 'react';
import { Button } from '@/lib/Button';
import Link from 'next/link';

const FEEDBACK_OPTIONS = [
  { label: 'Feedback', value: 'feedback' },
  { label: 'Complain', value: 'complain' },
  { label: 'Bug report', value: 'bug_report' },
  { label: 'Other', value: 'other' },
];

export const ContactUsModal = ({ onCloseModal }) => {
  const [feedbackType, setFeedbackType] = useState(FEEDBACK_OPTIONS[0]);

  const methods = useForm({
    defaultValues: {
      email: '',
      comment: '',
    },
    mode: 'onSubmit',
  });

  const handleFeedbackTypeChange = (value) => {
    setFeedbackType(value);
  };

  const onSubmit = (data) => {
    return data;
  };

  return (
    <BaseModal title="Request help / send feedback" onClose={onCloseModal}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex vertical gap={32}>
            <div>
              Please contact us via email{' '}
              <Link href="#">
                <span>help@8legends.ai</span>
              </Link>
              {'\n'}
              or join our{' '}
              <Link href="#">
                <span>Telegram group</span>
              </Link>{' '}
              and ask directly to a team member
            </div>
            <Flex vertical gap={12}>
              <Select
                title="Feedback"
                selected={feedbackType}
                options={FEEDBACK_OPTIONS}
                onChange={handleFeedbackTypeChange}
              />
              <Input
                placeholder="Email"
                required
                name="email"
                pattern={/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/}
              />
              <TextArea
                name="comment"
                placeholder="How can we help you?"
                required
              />
            </Flex>
            <Flex gap={12} justify="space-between">
              <Button
                variant="filled"
                color="white"
                fullWidth
                onClick={onCloseModal}
              >
                Join TG
              </Button>
              <Button variant="filled" color="orange" type="submit" fullWidth>
                Send
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </BaseModal>
  );
};
