import { useEffect, useRef, useState } from 'react';
import { Button, Flex, BaseModal, Loader, Select } from '@/lib';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './SwapModal.module.scss';
import toast from 'react-hot-toast';
import { fetchData } from '@/services/fetchData';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';
import { initiateSwapNative, initiateSwapUSDT } from '@/services/Swap';
import { useConnectBahamut } from '@/hooks/useConnectBahamut';
import { getGlobalState } from '@/store';

export const SwapModal = ({ onClose }) => {
  const { isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { chainId } = useWeb3ModalAccount();
  const { connect } = useConnectBahamut(chainId);

  useEffect(() => {
    if (!isConnected) {
      connect();
      onClose();
    }
  }, [isConnected, open, onClose, connect]);

  if (!isConnected) {
    return null;
  }

  return (
    <BaseModal
      onClose={onClose}
      title="Cross-Chain Swap"
      titleClassName={styles.screenTitle}
      width="460px"
    >
      <SwapScreen />
    </BaseModal>
  );
};

const getNetworksData = (networks) => {
  if (!networks) return [{ value: 'Loading', label: 'Loading' }];

  const networkEntries = Object.entries(networks ?? {}).map((item) => {
    return item[1];
  });

  const networksDataArr = networkEntries.map((item) => {
    return {
      value: item.displayName,
      label: item.displayName,
      icon: item.icon,
    };
  });

  return { networksDataArr, networkEntries };
};

function SwapScreen() {
  const availableNetworks = getGlobalState('appNetworks');
  const dropdownRef = useRef(null);
  const [selectActive, setSelectActive] = useState(false);
  const { networksDataArr, networkEntries } =
    getNetworksData(availableNetworks);
  const [currentNetwork, setCurrentNetwork] = useState(networkEntries[0]);
  const [currentToken, setCurrentToken] = useState(currentNetwork.tokens[0]);
  const [selectedNetwork, setSelectedNetwork] = useState(networksDataArr[0]);
  const [exchangeRate, setExchangeRate] = useState(0);
  const account = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loadingRate, setLoadingRate] = useState(false);
  const [blockForm, setBlockForm] = useState(false);
  const [valueSend, setValueSend] = useState(
    currentToken.minDeposit.toString(),
  );
  const [valueReceive, setValueReceive] = useState('');

  const methods = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setValueReceive(Number(valueSend) / exchangeRate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRate]);

  useEffect(() => {
    const network = availableNetworks[selectedNetwork.value];

    setCurrentNetwork(network);
    setCurrentToken(network.tokens[0]);
  }, [selectedNetwork, availableNetworks]);

  const onSubmit = async () => {
    if (account.chainId !== currentNetwork.chainId) {
      if (!window.ethereum) return;

      try {
        toast.loading('Processing...');

        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: currentNetwork.chainIdHex }],
        });

        toast.dismiss();
        toast.success(`Network switched to ${currentNetwork.displayName}`);
      } catch (error) {
        if (error.code && error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: currentNetwork.chainHash,
                chainName: currentNetwork.networkName,
                nativeCurrency: {
                  name: currentNetwork.currency,
                  symbol: currentNetwork.currency,
                  decimals: 18,
                },
                rpcUrls: [currentNetwork.rpcUrl],
                blockExplorerUrls: [currentNetwork.explorerUrl],
              },
            ],
          });
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: currentNetwork.chainIdHex }],
          });

          return;
        }

        toast.dismiss();
        toast.error('Error while switching network');
      }
      return;
    }

    if (valueSend < currentToken.minDeposit) {
      toast.error(
        `Minimal exchange value for ${currentToken.name} token is ${currentToken.minDeposit}`,
      );
      return;
    }

    try {
      setBlockForm(true);
      toast.loading('Processing...');
      let transaction;

      if (currentToken.name === 'USDT') {
        transaction = await initiateSwapUSDT({
          walletProvider,
          contractAddress: currentNetwork.swapContractAddress,
          valueSend,
          usdtContactAddress: currentToken.contract,
          decimals: currentToken.decimals,
        });
      } else {
        transaction = await initiateSwapNative({
          walletProvider,
          contractAddress: currentNetwork.swapContractAddress,
          valueSend,
        });
      }

      if (transaction.hash) {
        setValueSend(0);

        const swap = await fetchData({
          url: `https://swap-backend.8legends.ai/swap/status/${transaction.hash}`,
        });

        if (swap.status === 'success') {
          toast.dismiss();
          toast.success(
            'Tokens successfully received and taken in processing 👌 Please wait a while',
          );

          setBlockForm(false);
        }
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        'Error while swap processing. Check your balance or try again later',
      );
      setBlockForm(false);
    }
  };

  const getExchangeRate = async (currency) => {
    setLoadingRate(true);

    const response = await fetchData({
      url: `https://swap-backend.8legends.ai/swap/price/${currency}`,
    }).finally(() => setLoadingRate(false));

    if (response && response.price) {
      setExchangeRate(Number(response.price));
    } else {
      toast.error('Error getting exchange rate');
    }
  };

  useEffect(() => {
    setValueSend(currentToken.minDeposit);
    getExchangeRate(currentToken.name);
  }, [currentToken]);

  const toggleSelect = () => {
    if (currentNetwork.tokens && currentNetwork.tokens.length > 1) {
      setSelectActive((prev) => !prev);
    }
  };

  const handleValueSendChange = (e) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^\d.]/g, '');
    setValueSend(filteredValue);
    setValueReceive(Number(filteredValue) / exchangeRate);
  };

  const handleValueReceiveChange = (e) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^\d.]/g, '');
    setValueReceive(filteredValue);
    setValueSend(Number(filteredValue) * exchangeRate);
  };

  const handleSetToken = (token) => {
    setCurrentToken(token);
    toggleSelect();
  };

  const handleChangeSelectNetwork = (network) => {
    setSelectedNetwork(network);
    setSelectActive(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex vertical gap={24}>
            <p className={styles.disclaimer}>
              <b>Please note:</b> Transaction processing may take a while. Feel
              free to contact support if tokens was not received for more than
              30 minutes
            </p>
            <Select
              options={networksDataArr}
              selected={selectedNetwork}
              onChange={handleChangeSelectNetwork}
            />
            <div className={styles.inputs}>
              <InputWithInnerLabel
                label="Send"
                value={valueSend}
                onInputChange={handleValueSendChange}
              >
                <div>
                  <div className={styles.networkSelect} onClick={toggleSelect}>
                    <img
                      src={currentToken.icon ?? '/png/empty.png'}
                      alt="network"
                      className={styles.icon}
                    />
                    <span>{currentToken.name}</span>
                    {currentNetwork.tokens &&
                      currentNetwork.tokens.length > 1 && (
                        <img
                          src="/svg/arrowRight.svg"
                          alt="arrow"
                          className={selectActive ? styles.up : styles.down}
                        />
                      )}
                  </div>
                  {selectActive && (
                    <div className={styles.dropdown} ref={dropdownRef}>
                      {currentNetwork.tokens.map((token, index) => (
                        <div
                          key={index}
                          className={styles.dropdownItem}
                          onClick={() => handleSetToken(token)}
                        >
                          <img
                            src={token.icon}
                            className={styles.icon}
                            alt="network"
                          />
                          <span>{token.name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </InputWithInnerLabel>

              <InputWithInnerLabel
                label="Receive"
                value={valueReceive}
                onInputChange={handleValueReceiveChange}
                loading={loadingRate}
              >
                <Flex justify="flex-end" align="center" gap={6}>
                  <img src="/svg/ftn.svg" alt="ftn" />
                  <span>FTN</span>
                </Flex>
              </InputWithInnerLabel>
            </div>

            <div className={styles.actions}>
              <Button
                color="white"
                type="submit"
                fullWidth
                disabled={blockForm}
                size="small"
              >
                {account.chainId !== currentNetwork.chainId
                  ? 'Switch network'
                  : 'Confirm'}
              </Button>
            </div>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
}

function InputWithInnerLabel({
  label,
  value,
  onInputChange,
  children,
  loading,
}) {
  const [isActive, setActive] = useState(false);

  return (
    <div className={`${styles.inputField} ${isActive ? styles.active : ''}`}>
      <span>{label}</span>
      <Flex justify="space-between" align="center" style={{ height: 40 }}>
        {loading ? (
          <Loader className={styles.loader} />
        ) : (
          <input
            value={value}
            onChange={onInputChange}
            placeholder={0}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            type="text"
            className={styles.input}
            min={0}
          />
        )}
        {children}
      </Flex>
    </div>
  );
}
