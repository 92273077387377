import cn from 'classnames';
import styles from './WaveVector.module.scss';

export function WaveVector({ className }) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName}>
      <img src="/svg/wave.svg" alt="vector" className={styles.wave} />
    </div>
  );
}
