import React, { useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import cn from 'classnames';
import { Button } from '../Button';
import styles from './BaseModal.module.scss';
import { Flex } from '..';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants';

export const BaseModal = ({
  children,
  onClose,
  title,
  width,
  titleClassName,
  onBackClick,
  noCloseButton,
}) => {
  const modalRef = useRef(null);
  const windowWidth = useWindowWidth();
  const isTablet = windowWidth && windowWidth < TABLET_RESOLUTION;

  const modalMobileStyles = {
    width: '100%',
    padding: '36px 20px',
    gap: '32px',
    borderRadius: '0',
    border: 'none',
    background: '#0e0e0e',
    backdropFilter: 'blur(60px)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
    minHeight: '100%',
  };

  const modalStyles = {
    width: width ? width : '612px',
    padding: '32px',
    gap: '32px',
    borderRadius: '20px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    background: 'rgba(29, 31, 36, 0.4)',
    backdropFilter: 'blur(60px)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
  };

  useEffect(() => {
    document.body.classList.add('modal-opened');

    return () => {
      document.body.classList.remove('modal-opened');
    };
  }, []);

  return (
    <Popup
      open={true}
      closeOnDocumentClick
      onClose={onClose}
      modal
      contentStyle={isTablet ? modalMobileStyles : modalStyles}
      ref={modalRef}
    >
      <Flex vertical gap={24} className={styles.modalBody}>
        <Flex justify="space-between" align="center">
          {onBackClick && (
            <Button
              variant="ghost"
              onClick={onBackClick}
              icon="/svg/arrowRight.svg"
              className={styles.backButton}
            />
          )}
          {title && (
            <h3 className={cn(styles.modalTitle, titleClassName)}>{title}</h3>
          )}
          {!noCloseButton && (
            <Button
              variant="ghost"
              onClick={onClose}
              icon="/svg/close.svg"
              className={styles.icon}
            />
          )}
        </Flex>
        <Flex vertical gap={32}>
          {children}
        </Flex>
      </Flex>
    </Popup>
  );
};
