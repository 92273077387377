import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import Link from 'next/link';
import styles from './HeaderUserAvatar.module.scss';
import { Button } from '@/lib/Button';
import { truncate } from '@/utils/truncate';
import { Loader, ProfileIcon } from '@/lib';
import { getGlobalState, useGlobalState } from '@/store';

export const HeaderUserAvatar = () => {
  const [userCard, setUserCard] = useState(false);
  const containerRef = useRef(null);
  const { disconnect } = useDisconnect();
  const [loading, setLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const balance = getGlobalState('balance');
  const [connectedAccount] = useGlobalState('connectedAccount');
  const { chainId } = useWeb3ModalAccount();

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (connectedAccount && isClient) {
      setLoading(false);
    }
  }, [connectedAccount, isClient]);

  const handleDisconnect = useCallback(async () => {
    try {
      disconnect().then(() => {
        window.location.reload();
      });
    } catch (e) {
      return e;
    }
  }, [disconnect]);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setUserCard(false);
    }
  };

  const renderBalance = (balance) => {
    const tmp = String(balance);
    if (tmp?.length > 10) {
      return tmp.slice(0, 10).concat('...');
    }
    return tmp;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <Button variant="filled" color="gray" className={styles.container}>
      <div
        className={styles.userAvatarContainer}
        onClick={() => {
          setUserCard(!userCard);
        }}
      >
        <img src="/svg/ftnWhite.svg" alt="user avatar" />
        <p className={styles.accountText}>
          {truncate(connectedAccount, 4, 4, 11)}
        </p>
      </div>
      {userCard && (
        <div className={styles.userCard} ref={containerRef}>
          <div className={styles.userCardContent}>
            <div className={styles.balanceInfo}>
              <div className={styles.balanceRow}>
                <p className={styles.balanceLabel}>Balance</p>
                <div className={styles.accountBadge}>
                  <p className={styles.accountText}>
                    {truncate(connectedAccount, 4, 4, 11)}
                  </p>
                  <div className={styles.statusIndicator} />
                </div>
              </div>
              <div className={styles.cryptoInfo}>
                <img
                  src="/svg/ftnWhite.svg"
                  className={styles.cryptoIcon}
                  alt="crypto icon"
                />
                <p className={styles.balanceAmount}>{renderBalance(balance)}</p>
                <p className={styles.balanceCurrency}>
                  {chainId === 5165 ? 'FTN' : 'Tokens'}
                </p>
              </div>
            </div>
            <div className={styles.actionLinks}>
              <Link
                className={styles.profileLink}
                onClick={() => {
                  setUserCard(false);
                }}
                href="/profile"
              >
                <div className={styles.profileIconWrapper}>
                  <ProfileIcon className={styles.profileIcon} />
                </div>
                <p className={styles.linkText}>My profile</p>
              </Link>
              <div className={styles.disconnectLink} onClick={handleDisconnect}>
                <div className={styles.disconnectIconWrapper}>
                  <img
                    src="/svg/link.svg"
                    className={styles.disconnectIcon}
                    alt="disconnect icon"
                  />
                </div>
                <p className={styles.linkText}>Disconnect</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Button>
  );
};
