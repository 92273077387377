import cn from 'classnames';
import styles from './Sphere.module.scss';

export const Sphere = ({ size, className }) => {
  if (size === 'small')
    return (
      <img
        src="/png/bannerSmall.png"
        className={cn(styles.smallSphere, className)}
        alt="background"
      />
    );

  return (
    <img
      src="/png/bannerBig.png"
      className={cn(styles.bigSphere, className)}
      alt="background"
    />
  );
};
